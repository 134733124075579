<script>
	import '$lib/app.postcss';
	import Pixels from '$lib/Components/Pixels/Pixels.svelte';
	import Header from '$lib/PageSections/Header/Header.svelte';
	import { links } from '$lib/PageSections/Header/links';
	import {
		uiState,
		userData,
	} from '$lib/PageSections/Portal/userData.svelte.js';
	import { setContext } from 'svelte';
	import { Toaster } from 'svelte-french-toast';
	import { writable } from 'svelte/store';

	let { children } = $props();

	setContext('sessionData', { user: writable({}) });
	setContext('userData', userData);
	setContext('uiState', uiState);
</script>

<Pixels />

<Toaster />
<Header {links} />
{@render children?.()}
