<script>
	import { onNavigate } from '$app/navigation';
	import { page } from '$app/stores';
	import Button from '$lib/Components/Layout/Button/Button.svelte';
	import Image from '$lib/Components/Layout/Image/Image.svelte';
	import { getContext } from 'svelte';
	import { fly, slide } from 'svelte/transition';
	import imgSearch from './images/Magnifying_glass_icon.svg';
	import imgProducts from './images/products-white.svg';
	import imgResources from './images/resources-white.svg';
	import Logo from './Logo.svelte';

	let userData = getContext('userData');
	let signedIn = $derived(userData.signedIn);

	let profileLinks = $derived(
		signedIn
			? [
					{
						href: '/my/dashboard',
						img: imgResources,
						name: 'My Dashboard',
					},
					{
						href: '/my/profile',
						img: imgProducts,
						name: 'My Profile',
					},
				]
			: [
					{
						href: '/indow-cost',
						name: 'Sign Up',
					},
					{
						href: '/signin',
						name: 'Sign In',
					},
				],
	);

	let { links = [], showSearch = false, showMobileBanner = true } = $props();

	let navInput = $state();
	let profileInput = $state();

	let closeMenus = () => {
		closeNav();
		closeProfile();
	};
	let closeProfile = () => {
		profileInput.checked = false;
	};
	let closeNav = () => {
		navInput.checked = false;
	};

	let innerWidth = $state();

	let getTransition = (node) => {
		return innerWidth < 768
			? fly(node, { y: '-150%' })
			: slide(node, { axis: 'x' });
	};

	function isActive(link) {
		if ($page.url.pathname.startsWith(link.href)) {
			return true;
		}
		if (link.children) {
			return link.children?.some((child) => $page.url.pathname === child.href);
		}
		return false;
	}
	onNavigate(() => {
		closeMenus();
	});
</script>

<svelte:window bind:innerWidth />
<header
	class:fixed={$page?.data?.headerPosition != 'relative'}
	class="content-grid full-width top-0 isolate z-50 grid w-full grid-rows-[auto_1fr] place-items-center
	bg-[var(--indow-blue)] shadow-md md:relative md:bg-white print:hidden [&_*]:min-w-0"
>
	{#if !$page?.url.pathname.startsWith('/my')}
		<div
			transition:slide
			class="banner full-width grid w-full place-items-center justify-self-start bg-[var(--indow-blue)] py-4 text-white"
			class:max-md:!hidden={!showMobileBanner}
		>
			<div
				class="grid w-full grid-cols-[1fr_auto] justify-end
			"
			>
				<p
					class="m-0 h-4 !place-self-end !text-left text-sm font-medium text-white md:h-auto md:text-right"
				>
					Save up to $1,200 on thermal window inserts.
					<a
						href="/window-insert-tax-savings"
						class="!mr-0 ml-4 !gap-4 !p-0 font-bold !capitalize italic text-white !underline"
						>Learn More</a
					>
				</p>
			</div>
		</div>
	{/if}
	<div class="nav-container mx-auto grid w-full grid-cols-1 items-center">
		<nav
			class="relative grid w-full grid-cols-[auto_1fr_auto] text-xl leading-4
			md:grid-cols-[auto_1fr_auto_auto] md:grid-rows-1 md:items-center md:gap-4"
			class:md:grid-cols-[auto_1fr_auto_auto_auto]={showSearch}
		>
			<Logo on:click={closeMenus} />
			<input
				class="nav-button peer/nav hidden"
				type="checkbox"
				name="nav"
				id="nav-button"
				bind:this={navInput}
				onchange={closeProfile}
			/>
			<label
				class="menu-icon relative inline-block cursor-pointer select-none justify-end justify-self-end px-4 py-7 md:hidden"
				for="nav-button"
				><span
					class="navicon relative block h-0.5 w-6 bg-white before:absolute before:top-2 before:block
					before:h-full before:w-full before:bg-white before:transition-transform before:duration-200
					before:ease-out before:content-[''] after:absolute after:-top-2 after:block after:h-full
					after:w-full after:bg-white after:transition-transform after:duration-200 after:ease-out
					after:content-['']"
				></span></label
			>
			<input
				class="nav-button peer/profile hidden"
				type="checkbox"
				name="menu"
				id="profile-button"
				bind:this={profileInput}
				onchange={closeNav}
			/>
			<!-- svelte-ignore a11y_click_events_have_key_events -->
			<!-- svelte-ignore a11y_no_noninteractive_element_interactions -->
			<label
				class="relative flex h-full cursor-pointer flex-col place-content-center items-center self-center peer-checked/profile:text-indow-blue md:col-start-4 md:hover:text-indow-blue md:focus:text-indow-blue [&_svg]:border-2 [&_svg]:border-transparent [&_svg]:peer-checked/profile:text-indow-blue max-md:[&_svg]:text-indow-white max-md:[&_svg]:peer-checked/profile:border-indow-white max-md:[&_svg]:peer-checked/profile:bg-indow-white"
				class:md:!text-indow-blue={$page.url.pathname.startsWith('/signin') ||
					$page.url.pathname.startsWith('/my')}
				for="profile-button"
				onclick={closeNav}
			>
				<div class="m-0 h-8 w-8">
					<svg
						class="rounded-full transition-colors duration-500 md:text-current"
						class:md:!text-indow-blue={$page.url.pathname.startsWith(
							'/signin',
						) || $page.url.pathname.startsWith('/my')}
						class:!text-indow-blue={$page.url.pathname.startsWith('/signin')}
						class:max-md:border-indow-white={$page.url.pathname.startsWith(
							'/signin',
						)}
						class:max-md:bg-indow-white={$page.url.pathname.startsWith(
							'/signin',
						)}
						xmlns="http://www.w3.org/2000/svg"
						fill="currentColor"
						viewBox="0 0 45.532 45.532"
						><path
							fill="currentColor"
							d="M22.766.001A22.77 22.77 0 0 0 0 22.766a22.77 22.77 0 0 0 22.766 22.765c12.574 0 22.766-10.192 22.766-22.765S35.34.001 22.766.001zm0 6.807a7.53 7.53 0 1 1 0 15.06 7.53 7.53 0 1 1 0-15.06zm-.005 32.771c-4.149 0-7.949-1.511-10.88-4.012a3.21 3.21 0 0 1-1.126-2.439c0-4.217 3.413-7.592 7.631-7.592h8.762c4.219 0 7.619 3.375 7.619 7.592a3.2 3.2 0 0 1-1.125 2.438 16.7 16.7 0 0 1-10.881 4.013z"
						/></svg
					>
				</div>
				<span
					class="text-sm font-semibold uppercase transition-colors duration-500 max-md:hidden"
				>
					{#if userData.signedIn}
						Dashboard
					{:else}
						Sign In
					{/if}
				</span>
			</label>
			<div
				class="col-span-full grid grid-rows-[0fr] transition-[grid-template-rows] duration-300 ease-in-out peer-checked/profile:grid-rows-[1fr] md:absolute md:right-0 md:top-full md:bg-indow-white"
			>
				<div class="overflow-hidden md:shadow-md">
					<ul
						class="row-start-2 mb-0 grid max-h-[85dvh] w-full
				 list-none flex-col justify-items-start
				 overflow-y-auto overflow-x-hidden py-8 uppercase ease-out md:border-t-2 md:border-indow-blue md:px-4 md:py-0"
					>
						{#each profileLinks as link}
							<li
								class="relative grid w-full grid-cols-[1fr_auto] text-left outline
								outline-1 outline-transparent
							transition-[outline] duration-500 max-md:items-center max-md:hover:outline-1
							max-md:hover:outline-indow-white md:h-full md:place-items-center md:text-center
							md:before:absolute md:before:top-0 md:before:h-[3px] md:before:w-[80%] md:before:bg-indow-blue
							md:before:opacity-0 md:before:transition md:before:duration-500 md:focus-within:text-indow-blue"
								class:active={isActive(link)}
							>
								<a
									class="grid h-fit grid-cols-[1.5rem_1fr] items-center justify-start gap-4
							hyphens-none py-4 text-left uppercase text-indow-white no-underline transition-colors
							duration-500 md:flex md:h-full md:w-full md:list-none md:flex-row md:items-center md:gap-6 md:py-4 md:text-base
							md:font-bold md:text-indow-grey-dark md:hover:text-indow-blue md:focus:text-indow-blue"
									href={link.href}
									onclick={closeMenus}
								>
									<div class="inline md:hidden">
										<Image src={link.img} />
									</div>
									{link.name}
								</a>
							</li>
						{/each}
						{#if userData.signedIn}
							<li
								class="relative grid w-full grid-rows-[auto_1fr] text-left
								outline outline-1
							outline-transparent transition-[outline] duration-500 max-md:items-center
							max-md:hover:outline-1 max-md:hover:outline-indow-white md:h-full md:place-items-center
							md:text-center md:before:absolute md:before:top-0 md:before:h-[3px] md:before:w-[80%]
							md:before:bg-indow-blue md:before:opacity-0 md:before:transition md:before:duration-500 md:focus-within:text-indow-blue"
							>
								<hr class="mb-2" />
								<p
									class="mb-0 grid h-fit max-w-full items-center justify-start
							gap-4 overflow-ellipsis hyphens-none whitespace-nowrap py-0 text-left
							text-xs lowercase text-indow-white no-underline transition-colors duration-500 md:flex md:h-full md:w-full md:list-none md:flex-row
							md:items-center
							md:gap-4 md:font-bold md:text-indow-grey"
								>
									{userData.signedIn}
								</p>
								<a
									class="grid h-fit grid-cols-[1.5rem_1fr] items-center justify-start gap-4
							hyphens-none py-4 text-left uppercase text-indow-white no-underline transition-colors
							duration-500 md:h-full md:w-full md:list-none md:flex-row md:items-center md:py-4 md:pb-4 md:pt-2 md:text-base
							md:font-bold md:text-indow-grey md:hover:text-indow-blue md:focus:text-indow-blue"
									data-sveltekit-preload-data="off"
									href="/signout"
									onclick={() => {
										closeMenus();
										userData.signedIn = false;
									}}
								>
									<div class="inline justify-self-center">
										<svg
											id="Layer_1"
											version="1.1"
											viewBox="0 0 24 24"
											xml:space="preserve"
											xmlns="http://www.w3.org/2000/svg"
											xmlns:xlink="http://www.w3.org/1999/xlink"
											class="h-4 stroke-current"
											><g
												><g
													><line
														class="st1"
														x1="7.6"
														x2="22"
														y1="12"
														y2="12"
													/></g
												><g
													><path
														class="st1"
														d="M11.9,0.8H4.5C3.1,0.8,2,1.9,2,3.2v17.5c0,1.4,1.1,2.5,2.5,2.5h7.4"
													/></g
												><polyline
													class="st1"
													points="18.2,8.2 22,12 18.2,15.8  "
												/></g
											></svg
										>
									</div>
									Sign Out
								</a>
							</li>
						{/if}
					</ul>
				</div>
			</div>
			<div
				class="grid grid-rows-[0fr] transition-[grid-template-rows] duration-300 ease-in-out peer-checked/nav:grid-rows-[1fr] max-md:col-span-full md:col-start-2 md:row-span-full"
			>
				<div class="overflow-hidden md:overflow-visible">
					<ul
						class=" menu justify-inherit col-start-1 col-end-3 row-start-2 mb-0 flex max-h-[85dvh] w-full
				 list-none flex-col overflow-y-auto overflow-x-hidden
				py-8 uppercase ease-out
				md:relative md:col-start-2 md:row-start-1 md:m-0 md:h-36 md:min-h-36 md:list-none md:flex-row
				md:items-center md:justify-center md:gap-6 md:overflow-visible md:p-0 [&_li]:mb-0"
						class:topButton={!$page?.data?.hideHeaderButton}
					>
						{#each links as link}
							<li
								class="relative grid grid-cols-[1fr_auto] text-left outline outline-1
								outline-transparent
							transition-[outline] duration-500 max-md:items-center max-md:hover:outline-1
							max-md:hover:outline-indow-white md:h-full md:place-items-center md:text-center
							md:before:absolute md:before:top-0 md:before:h-[3px] md:before:w-[80%] md:before:bg-indow-blue
							md:before:opacity-0 md:before:transition md:before:duration-500 md:before:content-[''] md:focus-within:text-indow-blue"
								class:md:hidden={link.hideDesktop}
								class:active={isActive(link)}
							>
								<a
									class="grid h-fit grid-cols-[1.5rem_1fr] items-center gap-4 hyphens-none
							py-4 uppercase text-indow-white no-underline transition-colors duration-500 md:flex
							md:h-full md:w-full md:items-center md:justify-center md:p-0 md:text-base
							md:font-bold md:text-indow-grey-dark md:hover:text-indow-blue md:focus:text-indow-blue"
									href={link.href}
									onclick={closeMenus}
								>
									<div class="inline md:hidden">
										<Image src={link.img} />
									</div>
									{link.name}
								</a>
								{#if link.children}
									<input
										class="nav-button peer hidden h-full w-full"
										type="checkbox"
										id={link.name}
									/>
									<label
										class="menu-icon translate-x h-fit w-full cursor-pointer p-4 text-white transition-transform
										duration-500 peer-checked:rotate-180 md:hidden"
										for={link.name}>▼</label
									>
									<div
										class="grid grid-rows-[0fr] transition-[grid-template-rows] duration-300 ease-in-out peer-checked:grid-rows-[1fr] max-md:col-span-full"
									>
										<div class="overflow-hidden md:overflow-visible">
											<ul
												class="menu justify-inherit duration-200s md:duration-200s col-start-1 col-end-3
								row-start-2 flex w-full list-none flex-col gap-4 overflow-hidden
								overflow-x-hidden pb-4 uppercase transition-[max-height]
								ease-out peer-checked:pb-4 peer-checked:pt-4 md:pointer-events-none md:absolute
								md:-left-8 md:top-0 md:col-start-1 md:grid md:w-max
								md:flex-col md:place-content-start md:gap-0 md:overflow-visible
								md:bg-indow-white md:opacity-0 md:shadow-md md:transition md:duration-500 md:peer-checked:pb-0"
												aria-label="submenu"
											>
												{#each link.children as child}
													<li
														class="md:first:border-t-solid
						relative grid pl-0 outline outline-1 outline-transparent transition-[outline] duration-500
										max-md:hover:outline-1 max-md:hover:outline-indow-white
										md:h-full md:w-full md:place-content-start md:place-items-start md:whitespace-nowrap md:bg-indow-white
						md:p-0 md:first:border-t-2
										md:first:border-t-indow-blue md:last:pb-4 md:focus-within:bg-indow-white md:hover:bg-indow-white"
														class:active={isActive(child)}
													>
														<a
															class="hyphens-none uppercase leading-normal text-indow-white
											no-underline transition-colors duration-500 md:flex md:h-full md:w-full
											md:items-center md:justify-start md:p-0 md:px-8 md:py-4 md:text-base
											md:font-bold md:text-indow-grey-dark md:hover:text-indow-blue
											md:focus:text-indow-blue"
															href={child.href}
															onclick={closeMenus}
														>
															<div class="md:hidden">
																{#if child.img}
																	<Image src={child.img} />
																{/if}
															</div>
															{child.name}
														</a>
													</li>
												{/each}
											</ul>
										</div>
									</div>
								{/if}
							</li>
						{/each}
						{#if showSearch}
							<!-- TODO: connect this form to a real search page/component -->
							<li class="md:grid-col-auto col-span-2 md:hidden">
								<form
									action="https://indowwindows.com"
									role="search"
									class="w-inherit m-0 flex border-indow-grey-light px-2 py-0 md:rounded-br-lg
							md:rounded-tr-lg md:border-indow-white md:bg-indow-white"
								>
									<input
										type="search"
										title="Search"
										name="s"
										placeholder="Search"
										class="w-full cursor-pointer overflow-hidden rounded-br-none rounded-tr-none
								border-transparent px-4 py-0 md:max-w-0 md:p-0"
										required
									/>
									<button
										><Image
											width="20"
											height="20"
											alt="Search"
											class="m-2 h-4 w-auto rounded-br-md rounded-tr-md border-2 border-l-0 
									border-indow-grey-light bg-indow-grey-light "
											src={imgSearch}
										/></button
									>
								</form>
							</li>
						{/if}
					</ul>
				</div>
			</div>
			{#if !$page?.data?.hideHeaderButton && !userData.signedIn}
				<div
					transition:getTransition
					class="absolute left-0 top-0 row-span-full h-min w-full translate-y-[150%]
    					whitespace-nowrap md:relative md:bottom-auto md:col-start-3 md:flex md:w-auto md:translate-y-0"
				>
					<Button
						mobileHeight="thin"
						href={'/indow-cost'}
						color="yellow"
						border="white"
					>
						Get Estimate
					</Button>
				</div>
			{/if}
			{#if showSearch}
				<div class="max-md:hidden">
					<a class="search w-max" href="/search">
						<img
							class="search h-4"
							width="20"
							height="20"
							alt="Search"
							src={imgSearch}
						/>
					</a>
				</div>
			{/if}
		</nav>
	</div>
</header>

<style>
	.nav-button:checked ~ .menu-icon .navicon {
		background: transparent;
	}

	.nav-button:checked ~ .menu-icon .navicon:before {
		transform: rotate(-45deg);
	}

	.nav-button:checked ~ .menu-icon .navicon:after {
		transform: rotate(45deg);
	}

	@media (min-width: 768px) {
		li.active > a {
			color: var(--indow-blue);
		}

		li:not(.active):hover::before,
		li.active::before {
			opacity: 1;
		}

		li:not(.active):focus-within::before,
		li.active:focus-within::before {
			opacity: 1;
		}

		ul.menu li:hover ul {
			opacity: 1;
			pointer-events: initial;
		}
		ul.menu li:focus-within ul {
			opacity: 1;
			pointer-events: initial;
		}
	}

	.st1 {
		fill: none;
		stroke: inherit;
		stroke-width: 1.5;
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke-miterlimit: 10;
	}
</style>
